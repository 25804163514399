var config = {};

config.groupSearchForm = {
	variationIdentifier: function _variationIdentifier($el) {
		var variation;
		if ($el.hasClass(this.identifiers[this.variations.EDIT_SEARCH])) {
			variation = this.variations.EDIT_SEARCH;
		}
		if ($el.hasClass(this.identifiers[this.variations.ADVANCED_SEARCH])) {
			variation = this.variations.ADVANCED_SEARCH;
		}
		if ($el.hasClass(this.identifiers[this.variations.HOME_PAGE])) {
			variation = this.variations.HOME_PAGE;
		}

		return variation;
	},
	variations: {
		EDIT_SEARCH: 'EDIT_SEARCH',
		ADVANCED_SEARCH: 'ADVANCED_SEARCH',
		HOME_PAGE: 'HOME_PAGE'
	},
	identifiers: {
		EDIT_SEARCH: 'edit-search-variation',
		ADVANCED_SEARCH: 'advanced-search-variation',
		HOME_PAGE: 'home-page-variation'
	}
};

module.exports = config;