/* Component Dependencies */
var groupSearchFormTemplate = require('templates/groupSearchForm.hbs');
var AriesComponent = require('libs/aries-component');
var DateHandler = require('libs/date-handler');
var AutoCompleteHandler = require('libs/auto-complete-handler-v2');
var Utils = require('group_libs/utils');
var ResponsiveUtils = require('utils/responsiveUtils');
var EventTypeHandler = require('groupSearch_libs/eventTypeHandler');
var Config = require('groupSearch_libs/config');
var _ = require('lodash');

AriesComponent.create({
    type: 'groupSearchForm',

    template: {
        'groupSearchForm': groupSearchFormTemplate
    },

    subscribe: {
        'SHOW_GROUPS_SEARCH_FORM': 'showSearchForm',
        'ADVANCED_SEARCH_TAB_CLICK': 'showSearchForm',
        'STICKY_BEHAVIOR_ADDED': 'scrollView',
        'STICKY_BEHAVIOR_REMOVED': 'scrollView'
    },

    events: {
        'cut .js-pos-number, copy .js-pos-number, paste .js-pos-number': 'preventCutCopyPaste',
        'click .first-row': 'expandSearchForm',
        'change .js-event-space': 'updateSquareFootage',
        'click .js-form-close': 'collapseSearchForm'
    },

    bindEvents: function _bindEvents($) {
        var _self = this;
        this.$ = $;
        _self.$window = $(window);
        this.idPrefix = this.$el.data("id-prefix");
        this.populateData();
        this.initResponsiveUtils();
        this.initAutoCompleteHandler();
        this.initDateHandler();
        this.initEventTypeHandler();
        this.handleTabIndex();
        this.handleOnLoadInteractions();

        var $element = this.$el.find('.js-pos-number');
        Utils.validation.positiveNumber($element);

        this.variation = Config.groupSearchForm.variationIdentifier(this.$el);
        $(document).trigger('GLOBAL_ELEMENTS_REINIT.mi-select');

        this.$el.find('form').submit(function(event) {
            return _self.validateFormFields(event);
        });
    },
    scrollView: function scrollView() {
        var _self = this;
        if (_self.$el.hasClass('l-desktop-scroll')) {
            if (_self.$el.closest('.is-sticky-top').length) {
                _self.toggleView('l-desktop-collapsed', 'l-desktop-expand', 'closeOn-sticky-collapse', 'showOn-sticky-expand');
                _self.toggleScrollView(true);
            } else {
                _self.toggleView('l-desktop-expand', 'l-desktop-collapsed', ' ', 'closeOn-sticky-collapse');
                _self.toggleScrollView(false);
            }
        }
    },
    toggleScrollView: function showExpandScrollView(state) {
        var _self = this;
        _self.$el.find(".first-row").toggleClass("l-l-col-8 l-xl-col-8", state);
        _self.$el.find(".first-row .l-form-group").toggleClass("l-l-col-6 l-xl-col-6", state);
        _self.$el.find(".js-location").toggleClass("l-l-col-6 l-xl-col-6", state);
        _self.$el.find(".second-row").toggleClass("l-l-col-4 l-xl-col-4", state);
        _self.$el.find(".second-row #findBtn").toggleClass("l-l-col-12 l-xl-col-12", state);
    },
    populateData: function _populateData() {
        this.errorMessages = {};
        this.formClass = {};

        this.errorMessages.destination = this.$el.data('messageDestinationrequirederror');
        this.errorMessages.startDateTodayError = this.$el.data('messageStartdatetodayerror');
        this.errorMessages.guestRoomEventSpaceBlankError = this.$el.data('messageGuestroomeventspaceblankerror');
        this.errorMessages.startDateRequiredError = this.$el.data('messageStartdaterequirederror');
        this.errorMessages.endDateRequiredError = this.$el.data('messageEnddaterequirederror');
        this.errorMessages.startEndDateSameError = this.$el.data('messageStartenddatesameerror');
        this.errorMessages.eventSpaceBlankError = this.$el.data('messageEventspaceblankerror');

        this.formClass.HIDE_SEARCH_FORM = 'is-hidden';
        this.formClass.SEARCH_FORM_TAKEOVER = 'is-takeover';
        this.formClass.COLLAPSED_SEARCH_FORM = 'is-collapsed';
        this.formClass.HIDE_ELEMENT = 'is-hidden';
    },

    initResponsiveUtils: function _initResponsiveUtils() {
        this.responsiveUtils = new ResponsiveUtils.create();
        this.responsiveUtils.init();
    },

    initAutoCompleteHandler: function _initAutoCompleteHandler() {
        var locationField = this.$el.find('.js-auto-complete.js-location');
        locationField.find('input').addClass('js-auto-complete-input-v2');
        this.autoCompleteHandler = AutoCompleteHandler.create({
            $el: locationField,
            $parent: this
        });
        this.autoCompleteHandler.init();
    },

    initDateHandler: function _initDateHandler() {
        var _self = this;
        this.dateHandler = new DateHandler({
            $el: this.$el.find('.js-datepick-container'),
            $parent: _self,
            suppressTakeOver: true
        });
        this.dateHandler.init();
    },

    initEventTypeHandler: function _initEventTypeHandler() {
        this.eventTypeHandler = new EventTypeHandler({
            $el: this.$el.find('.l-purpose-of-event'),
            $parent: this
        });
        this.eventTypeHandler.init();
    },

    handleOnLoadInteractions: function _handleOnLoadInteractions() {
        var _self = this;
        $(document).ready(function() {
            _self.putFocusOnGuestRoomsField();
        });
    },

    putFocusOnGuestRoomsField: function _putFocusOnGuestRoomsField() {
        var $element = this.$el.find('.js-guest-rooms');
        if ($element.data('has-focus')) {
            $element.focus();
        }
    },

    setBodyOverflowHidden: function _setBodyOverflowHidden(set) {
        var cssProperty = {};
        if (set) {
            cssProperty.position = 'relative';
            cssProperty.overflow = 'hidden';
            cssProperty.height = '100%';
            $('body').addClass('is-form-takeover');
        } else {
            cssProperty.position = 'static';
            cssProperty.overflow = 'visible';
            $('body').removeClass('is-form-takeover');
        }

        $('body,html').css(cssProperty);
    },

    showSearchForm: function _showSearchForm(focusElement) {
        if (this.variation === Config.groupSearchForm.variations.ADVANCED_SEARCH) {
            if (focusElement !== 'meeting-space') {
                this.$el.addClass(this.formClass.HIDE_SEARCH_FORM);
                return false;
            }
        }
        if (this.responsiveUtils.isMobileOrTablet() && this.variation !== Config.groupSearchForm.variations.ADVANCED_SEARCH) {
            this.$el.addClass(this.formClass.SEARCH_FORM_TAKEOVER);
            this.setBodyOverflowHidden(true);
            this.$el.find('.l-takeover-head').removeClass(this.formClass.HIDE_ELEMENT);
        }
        this.$el.removeClass(this.formClass.HIDE_SEARCH_FORM);

        this.$(document).trigger('GLOBAL_ELEMENTS_REINIT.mi-select');

        if (focusElement) {
            if (this.$el.find('[data-target=\'' + focusElement + '\']').is('input')) {
                this.$el.find('[data-target=\'' + focusElement + '\']').focus();
            } else {
                this.$el.find('[data-target=\'' + focusElement + '\']').trigger('click').focus();
            }
        }
    },

    /**
     * This function is used to handle tab indexes on Search Forms
     */
    handleTabIndex: function() {
        var _self = this,
            i;
        var ele = ['.js-date-from', '.js-date-to', '.picker__nav--prev:not(.picker__nav--disabled)', '.picker__nav--next:not(.picker__nav--disabled)', '.js-date-clear', '.js-guest-rooms', '.js-event-space', '.event-type', '.m-button-primary'];
        var $searchInputId = '#' + _self.$el.find('.l-group-search-input').attr('id');

        _self.$el.find($searchInputId).off().on('focus').attr('tabIndex', 0);
        _self.$el.find($searchInputId).on('focusout').removeAttr('tabIndex');
        this.$el.find(ele.join(', ')).on('focus', function() {
            var tabIndex = 2;

            for (i in ele) {
                _self.$el.find(ele[i]).attr('tabIndex', tabIndex);
            }
        });

        this.$el.find(ele.join(', ')).not('.js-event-space, .js-guest-rooms, .js-date-clear, .picker__nav--next, .picker__nav--prev').on('focusout', function(e) {
            for (i in ele) {
                _self.$el.find(ele[i]).not('.js-date-clear, .event-type, .picker__nav--next, .picker__nav--prev').removeAttr('tabIndex');
            }
        });


        this.$el.on('keydown', '.picker__nav--prev, .picker__nav--next', function(e) {
            if (e.keyCode === 13) {
                $(this).trigger('click');
                var tabIndex = $(this).attr('tabIndex');
                if ($(this).hasClass('picker__nav--next')) {
                    _.delay(function() {
                        var navEl = _self.$el.find('.picker__nav--next');
                        navEl.attr('tabIndex', tabIndex);
                        navEl.focus();
                    }, 0);
                } else {
                    _.delay(function() {
                        var navEl = _self.$el.find('.picker__nav--prev');
                        navEl.attr('tabIndex', tabIndex);
                        navEl.focus();
                    }, 0);
                }
            }
        });
    },

    preventCutCopyPaste: function _preventCutCopyPaste(event) {
        event.preventDefault();
    },

    updateSquareFootage: function _updateSquareFootage(event) {
        var eventSpace = parseInt($(event.target).val());
        var squareFootage = eventSpace * 10;
        if (eventSpace) {
            this.$el.find('.square-footage-label').removeClass(this.formClass.HIDE_ELEMENT);
            this.$el.find('.js-square-footage').removeClass(this.formClass.HIDE_ELEMENT).html(squareFootage);
        } else {
            this.$el.find('.square-footage-label').addClass(this.formClass.HIDE_ELEMENT);
            this.$el.find('.js-square-footage').addClass(this.formClass.HIDE_ELEMENT);
        }
    },

    expandSearchForm: function _expandSearchForm() {
        var _self = this;
        if (this.responsiveUtils.isMobileOrTablet() && this.variation !== Config.groupSearchForm.variations.ADVANCED_SEARCH) {
            this.$el.removeClass(this.formClass.COLLAPSED_SEARCH_FORM).addClass(this.formClass.SEARCH_FORM_TAKEOVER);
            this.setBodyOverflowHidden(true);
            this.$el.find('.l-takeover-head').removeClass(this.formClass.HIDE_ELEMENT);
        }
        if (_self.responsiveUtils.isDesktop() && _self.$el.closest('.is-sticky-top').length && _self.$el.hasClass('l-desktop-scroll')) {
            this.toggleView('l-desktop-expand', 'l-desktop-collapsed', 'showOn-sticky-expand', 'closeOn-sticky-collapse');
            this.toggleScrollView(false);
        }
    },

    collapseSearchForm: function _collapseSearchForm() {
        var _self = this;
        var property_class = '';
        var hide_form_class = this.formClass.HIDE_SEARCH_FORM;
        this.pubsub.publish("MAP_BOTTOM_AREA_ADJUST", [this.$el.outerHeight()]);
        if (this.variation === Config.groupSearchForm.variations.HOME_PAGE) {
            property_class = this.formClass.COLLAPSED_SEARCH_FORM;
            hide_form_class = '';
        }
        this.$el.find('.l-takeover-head').addClass(this.formClass.HIDE_ELEMENT);
        this.$el.removeClass(this.formClass.SEARCH_FORM_TAKEOVER).addClass(property_class);
        this.setBodyOverflowHidden(false);
        this.$el.addClass(hide_form_class);
        this.pubsub.publish('SHOW_STICKY_GROUPS_SUMMARY');
        this.pubsub.publish("MAP_BOTTOM_AREA_ADJUST");
        if (_self.responsiveUtils.isDesktop() && _self.$el.closest('.is-sticky-top').length && _self.$el.hasClass('l-desktop-scroll')) {
            this.toggleView('l-desktop-collapsed', 'l-desktop-expand', 'closeOn-sticky-collapse', 'showOn-sticky-expand');
            this.toggleScrollView(true);
        }
    },
    validateFormFields: function _validateFormFields(event) {
        if (!this.validateDestinationFieldIsRequired(event)) {
            return false;
        }
        if (!this.validateStartDateIsNotTodaysDate(event)) {
            return false;
        }
        if (!this.validateBothGuestRoomsEventSpaceNotEmpty(event)) {
            return false;
        }
        if (!this.validateStartDateEndDateIsSame(event)) {
            return false;
        }
        if (!this.validateGuestRoomCount(event)) {
            return false;
        }
        if (this.validateDatesNotEmpty()) {
            if (!this.validateStartDateIsRequired(event)) {
                return false;
            }
            if (!this.validateEndDateIsRequired(event)) {
                return false;
            }
        }
    },

    //Destination field should not be empty
    validateDestinationFieldIsRequired: function _validateDestinationField(events) {
        if (this.$el.find('.js-search-location').val().trim() === '') {
            alert(this.errorMessages.destination);
            return false;
        }
        return true;
    },

    //Start date is mentioned as today's date
    validateStartDateIsNotTodaysDate: function _validateStartDateIsNotTodaysDate(event) {
        var startDateTimeString = this.dateHandler.from.date && this.dateHandler.from.date.getTime();
        var todaysDateTimeString = this.dateHandler.options.dates.todaysDate;

        if (startDateTimeString === todaysDateTimeString) {
            alert(this.errorMessages.startDateTodayError);
            return false;
        }
        return true;
    },

    //Both Guest Rooms and Event Space is left or both are zero
    validateBothGuestRoomsEventSpaceNotEmpty: function _validateBothGuestRoomsEventSpaceNotEmpty(event) {
        var guestRoomsCount = parseInt(this.$el.find('.js-guest-rooms').val());
        var eventSpaceCount = parseInt(this.$el.find('.js-event-space').val());

        if (!guestRoomsCount && !eventSpaceCount) {
            alert(this.errorMessages.guestRoomEventSpaceBlankError);
            return false;
        }
        return true;
    },

    //Guest room # is non zero and start/end date is same
    validateStartDateEndDateIsSame: function _validateStartDateEndDateIsSame(event) {
        var guestRoomsCount = parseInt(this.$el.find('.js-guest-rooms').val());
        var startDateTimeString = this.dateHandler.from.date && this.dateHandler.from.date.getTime();
        var endDateTimeString = this.dateHandler.to.date && this.dateHandler.to.date.getTime();

        if (guestRoomsCount > 0 && startDateTimeString && startDateTimeString === endDateTimeString) {
            alert(this.errorMessages.startEndDateSameError);
            return false;
        }
        return true;
    },

    //Room number is less than 10 and event space is blank or zero
    validateGuestRoomCount: function _validateGuestRoomCount(event) {
        var guestRoomsCount = this.$el.find('.js-guest-rooms').val() && parseInt(this.$el.find('.js-guest-rooms').val());
        var eventSpaceCount = this.$el.find('.js-event-space').val() && parseInt(this.$el.find('.js-event-space').val());

        if (guestRoomsCount && guestRoomsCount < 10 && !eventSpaceCount) {
            alert(this.errorMessages.eventSpaceBlankError);
            return false;
        }
        return true;
    },

    //Do not validate if Start and End date are empty
    validateDatesNotEmpty: function _validateDatesNotEmpty() {
        if (!this.dateHandler.from.date && !this.dateHandler.to.date) {
            return false;
        }
        return true;
    },

    //When end date is entered and start date is left blank
    validateStartDateIsRequired: function _validateStartDateIsRequired(event) {
        var startDateTimeString = this.dateHandler.from.date && this.dateHandler.from.date;
        if (!startDateTimeString) {
            alert(this.errorMessages.startDateRequiredError);
            return false;
        }
        return true;
    },

    //When start date is entered and end date is left blank
    validateEndDateIsRequired: function _validateEndDateIsRequired(event) {
        var endDateTimeString = this.dateHandler.to.date && this.dateHandler.to.date;
        if (!endDateTimeString) {
            alert(this.errorMessages.endDateRequiredError);
            return false;
        }
        return true;
    },

    // Desktop View Expand/ Collapse toggle Function
    toggleView: function _toggleView(parentAddClass, parentRemoveClass, crossButtonAddClass, crossButtonRemoveClass) {
        var _self = this;
        _self.$el.removeClass(parentRemoveClass).addClass(parentAddClass);
        _self.$el.find('.l-takeover-head').removeClass(crossButtonRemoveClass).addClass(crossButtonAddClass);
    }
});