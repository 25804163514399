var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
 
 /**
   * This method will validate positive numbers only
   * @param {event} key press event on text box.
   */
  var utils = {};

  utils.validation = {
      positiveNumber : function ($element){
        var validateNumber = new RegExp( /^[\d]*$/),
            allowedKeys = [8, 9, 46, 13, 35, 36, 37, 38, 39, 40, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 144]; 
        $element.on('paste', function(e) {
          var pastedValue = e.originalEvent.clipboardData.getData('text');
          if(false === validateNumber.test(pastedValue)) {
              e.preventDefault();
          }
        });
        $element.on('keydown', function(e) {
          // Don't let any key code work if shift is pressed except tab
          var keyCode = (e.shiftKey && e.which != 9) ? 0 : e.which;
          if(false === validateNumber.test(String.fromCharCode(keyCode)) && !e.ctrlKey && !e.altKey && !e.metaKey && allowedKeys.indexOf(keyCode) === -1 ) {
            e.preventDefault();
          }
        });
        $element.on('change', function(e) {
          var intValue = parseInt(this.value, 10);
          this.value = isNaN(intValue) ? '' : intValue;
        });
      },

      restrictNonPositiveNumber: function _restrictNonPositiveNumber(event) {
        // Restrict: minus and exponential
        if ($.inArray(event.keyCode, [189, 69]) !== -1) {
          event.preventDefault();
        }
      }
  }
  module.exports = utils;