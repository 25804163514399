var ComponentMapper = require('libs/component-mapper');

var properties = {
	init: function _init() {
	  this.$el.on('focusin', '.event-type', this.openEventTypeDropdown.bind(this));
	},

  openEventTypeDropdown: function _openEventTypeDropdown() {
  	this.$parent.$el.click();
  },
};

module.exports = function (props){
  var obj = _.cloneDeep(properties);
  var EventTypeHandlerInstance = ComponentMapper.extend(obj);
  return new EventTypeHandlerInstance(props);
}